import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { css } from '@emotion/core';
import { Row, Col } from 'emotion-flex';
import Container from './Container';

import Text from './Text';
import { ButtonLink } from '../common/Button';
import presets from '../../styles/presets';
import { File, ContentfulContentBlock } from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';
import { MonoFontLabel } from '../common/typography';

export default function Mission({
  prefix,
  title,
  text,
  callToAction,
}: ContentfulContentBlock) {
  const { bgImage, image } = useStaticQuery<{
    bgImage: File & { childImageSharp: { retina: { src: string } } };
    image: File;
  }>(graphql`
    {
      bgImage: file(relativePath: { regex: "/mission-desktop/i" }) {
        childImageSharp {
          fluid(maxWidth: 1451) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 1400) {
            src
          }
          retina: fixed(width: 2800) {
            src
          }
        }
      }
      image: file(relativePath: { regex: "/mission-mobile/i" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div
      css={{
        marginBottom: 138,
        [presets.lg]: {
          marginBottom: 236,
        },
      }}
    >
      <div
        style={{ backgroundPosition: 'center right', backgroundSize: '1440px' }}
        css={css`
          ${presets.xl} {
            min-height: 734px;
            background-position: center right;
            background-size: 1440px;
            background-repeat: no-repeat;
            background-image: url(${bgImage.childImageSharp.fixed.src});
            background-image: image-set(
              url(${bgImage.childImageSharp.fixed.src}) 1x,
              url(${bgImage.childImageSharp.retina.src}) 2x
            );
          }
        `}
      >
        <Image
          fluid={[
            image.childImageSharp.fluid,
            {
              ...bgImage.childImageSharp.fluid,
              media: '(min-width: 576px)',
            },
          ]}
          css={{
            display: 'block',
            visibility: 'visible',
            maxWidth: '100%',
            height: 'auto',
            userSelect: 'none',
            [presets.xl]: { display: 'none', visibility: 'hidden' },
          }}
        />
        <Container>
          <Row>
            <Col
              xs={12}
              xl={8}
              xlOrder={'last'}
              css={{ marginBottom: 64, [presets.xl]: { marginBottom: 0 } }}
            />
            <Col
              xs={12}
              xl={4}
              css={{
                [presets.xl]: { paddingTop: 139 },
              }}
            >
              <MonoFontLabel>{prefix}</MonoFontLabel>
              <h4
                css={{
                  fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
                  fontSize: 32,
                  color: '#160B2C',
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <Text
                dangerouslySetInnerHTML={{
                  __html: text.childMarkdownRemark.rawMarkdownBody,
                }}
              />
              {callToAction && callToAction.url && callToAction.isDisplayed && (
                <ButtonLink to={callToAction.url}>
                  {callToAction.text}
                </ButtonLink>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

import { css } from '@emotion/core';
import React, { FC } from 'react';
import { ContentfulLink } from '../../graphql-types';
import presets from '../../styles/presets';
import Video from '../Video';
import Container from './Container';

const containerStyle = css`
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 30px;
  margin-bottom: 30px;

  ${presets.md} {
    padding-left: 24px;
    padding-right: 24px;
  }

  ${presets.lg} {
    padding-bottom: 60px;
    max-width: 960px;
  }
`;

const videoStyle = css`
  width: 100%;
`;

const VideoSection: FC<ContentfulLink> = ({ url, text, isDisplayed }) => {
  if (!isDisplayed) {
    return null;
  }

  return (
    <Container css={containerStyle}>
      <Video videoSrcURL={url} videoTitle={text} css={videoStyle} />
    </Container>
  );
};

export default VideoSection;

import React from 'react';
import Image from 'gatsby-image';
import { ReactComponent as TopWave } from '../../assets/images/logos-top.svg';
import { ReactComponent as BottomWave } from '../../assets/images/logos-bottom.svg';
import { ContentfulImageLink } from '../../graphql-types';
import hexToRgba from 'hex-to-rgba';
import { FormattedMessage } from 'react-intl';
import { MONO_SYSTEM_FONTS } from '../../styles/typography';

interface LogosProps {
  logos: ContentfulImageLink[];
}

export default function Logos({ logos }: LogosProps) {
  return (
    <div css={{ marginBottom: 48 }}>
      <TopWave
        width="100%"
        height="43"
        css={{ display: 'block', width: '100%', height: 'auto' }}
      />
      <div
        css={{
          marginTop: -1,
          backgroundColor: '#fff5f1',
          paddingTop: 64,
          paddingBottom: 64,
        }}
      >
        <div
          css={{
            paddingLeft: 24,
            paddingRight: 24,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1050px',
          }}
        >
          <span
            css={{
              display: 'block',
              textAlign: 'center',
              fontFamily: ['Source Code Pro']
                .concat(MONO_SYSTEM_FONTS)
                .join(', '),
              fontWeight: 600,
              fontSize: 12,
              color: hexToRgba('#160B2C', 0.8),
              letterSpacing: 1,
              marginBottom: 24,
            }}
          >
            <FormattedMessage
              id="homepage.logos.accent"
              defaultMessage="As seen in"
            />
          </span>
          <div
            css={{
              display: 'grid',
              gridGap: 48,
              gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
              alignItems: 'center',
            }}
          >
            {logos &&
              logos.map((logo) => (
                <Image
                  key={logo.id}
                  fixed={logo.image.fixed}
                  alt={logo.image.title}
                  css={{ maxWidth: 120, margin: '0 auto' }}
                />
              ))}
          </div>
        </div>
      </div>
      <BottomWave
        width="100%"
        height="83"
        css={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
}

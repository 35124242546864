import React from 'react';
import { Row, Col } from 'emotion-flex';
import Container from './Container';
import Text from './Text';
import { ButtonLink } from '../common/Button';
import PerkCard from './PerkCard';
import presets from '../../styles/presets';
import { ContentfulContentBlock } from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';

export default function Science({
  title,
  text,
  items,
  callToAction,
}: ContentfulContentBlock) {
  const getColor = (index: number) => {
    switch (index) {
      case 0:
        return '#E2E8F7';
      case 1:
        return '#F0E9F3';
      case 2:
        return '#FEF7E7';
      case 3:
        return '#FDF5F6';
      default:
        return '#E2E8F7';
    }
  };
  return (
    <>
      <div
        css={{
          maxWidth: 402,
          margin: '0 auto',
          padding: '0 24px',
          textAlign: 'center',
        }}
      >
        <h4
          css={{
            fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 32,
            color: '#160B2C',
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div
        css={{
          maxWidth: 508,
          margin: '0 auto',
          padding: '0 24px',
          textAlign: 'center',
        }}
      >
        <Text
          css={{ b: { fontWeight: 400 } }}
          dangerouslySetInnerHTML={{
            __html: text.childMarkdownRemark.rawMarkdownBody,
          }}
        />
      </div>
      {items && (
        <Container css={{ marginBottom: 48 }}>
          <Row>
            <Col xs={12} lg={10} lgOffset={1}>
              <Row>
                {items.map((item, i) => (
                  <Col
                    key={item.id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    css={{
                      [presets.sm]: {
                        display: 'flex',
                      },
                    }}
                  >
                    <PerkCard
                      background={getColor(i)}
                      image={item.image}
                      title={item.heading}
                      text={item.shortText}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
      {callToAction && callToAction.url && callToAction.isDisplayed && (
        <div
          css={{
            textAlign: 'center',
            marginBottom: 64,
            [presets.md]: { marginBottom: 48 },
          }}
        >
          <ButtonLink to={callToAction.url}>{callToAction.text}</ButtonLink>
        </div>
      )}
    </>
  );
}

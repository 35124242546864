import React from 'react';
import { graphql } from 'gatsby';
import { useIntl, WrappedComponentProps } from 'react-intl';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HomepageView from '../components/Homepage';
import { ContentfulHomepage } from '../graphql-types';
import Text from '../components/Homepage/Text';
import { SYSTEM_FONTS } from '../styles/typography';

interface OwnProps {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    homepage: ContentfulHomepage;
  };
}

type Props = OwnProps & WrappedComponentProps;

const Homepage: React.FC<Props> = ({
  pathContext: { locale },
  data: { homepage },
}) => {
  const intl = useIntl();

  return (
    <Layout isNewLayout noHeader>
      <SEO
        title={homepage.seoTitle}
        description={homepage.seoDescription}
        lang={locale}
      />
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-5rem',
          marginBottom: '2rem',
        }}
      >
        <Text
          style={{
            fontSize: '20px',
            fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
            marginRight: '1rem',
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({
            id: 'global.app.navigate.to.diga.text',
            defaultMessage:
              'Wollten Sie zur deutschen DIGA-Website navigieren?',
          })}
          <a
            href={`/${locale}/`}
            style={{
              color: '#489F9D',
              fontWeight: 'bold',
              paddingLeft: '1rem',
            }}
          >
            {intl.formatMessage({
              id: 'global.app.navigate.to.diga.button',
              defaultMessage: 'Klicken sie hier',
            })}
          </a>
        </Text>
      </div>

      <HomepageView homepage={homepage} />
    </Layout>
  );
};

export default Homepage;

export const globalappQuery = graphql`
  query globalappQuery($locale: String!) {
    homepage: contentfulHomepage(node_locale: { eq: $locale }) {
      seoTitle
      seoDescription
      heroTitle
      heroText {
        childMarkdownRemark {
          rawMarkdownBody
          html
        }
      }
      appStoreDownloadLink {
        url
        isDisplayed
      }
      googlePlayStoreDownloadLink {
        url
        isDisplayed
      }
      video {
        text
        url
        isDisplayed
      }
      chatBubbles {
        id
        heading
        shortText
        image {
          fixed(width: 40) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      logos {
        id
        url
        image {
          title
          fixed(width: 120) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      scienceSection {
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          id
          heading
          shortText
          image {
            fluid(maxHeight: 73) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      trackingSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          id
          prefix
          heading
          shortText
          image {
            fluid(maxWidth: 275) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      employersSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      personalizedPrograms {
        prefix
        title
        items {
          id
          heading
          shortText
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      missionSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      scienceAdvisor {
        prefix
        title
        items {
          id
          heading
          image {
            fluid(maxHeight: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          shortText
        }
      }
      featureHeader {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
        items {
          id
          heading
        }
      }
    }
  }
`;

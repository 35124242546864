import React from 'react';
import { css, InterpolationWithTheme } from '@emotion/core';
import words from 'lodash/words';
import { useIntl } from 'react-intl';
import AppDownloadButtons from './common/appbuttons/AppDownloadButtonsRow';
import Text from './Homepage/Text';
import ChatBubble from './ChatBubble';
import presets from '../styles/presets';
import { ReactComponent as EyeLashes } from '../assets/images/eyelashes.svg';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulLink,
} from '../graphql-types';
import { SYSTEM_FONTS } from '../styles/typography';
import hexToRgba from 'hex-to-rgba';
import Markdown from 'react-markdown/with-html';
import { ButtonLink } from './common/Button';
import { MonoFontLabel } from './common/typography';
import {
  eligibleTitle,
  typeformEligiblityCheckerLinkProduct,
} from '../utils/constants';

interface HeroProps {
  title: string;
  text: string;
  chatBubbles: ContentfulImageHeadingAndShortText[];
  hideChatBubbles?: boolean;
  variant: 'primary' | 'secondary';
  heroDE: string;
  heroEN: string;
  appStoreLink?: ContentfulLink;
  playStoreLink?: ContentfulLink;
  subTitle?: string;
}

export default function Hero({
  title,
  text,
  chatBubbles,
  hideChatBubbles,
  variant,
  heroDE,
  heroEN,
  appStoreLink,
  playStoreLink,
  subTitle,
}: HeroProps) {
  const match = title.match(/<b>(.*?)<\/b>/);
  const boldText = match ? match.pop() : null;
  const lastWord = match ? words(boldText)[words(boldText).length - 1] : null;
  const intl = useIntl();

  const containerStyles: InterpolationWithTheme<unknown> =
    variant === 'primary'
      ? {
          maxWidth: 750,
          margin: '0 auto 16px',
          padding: '0 24px',
          textAlign: 'center',
        }
      : { maxWidth: 648, margin: '0 auto 16px', textAlign: 'center' };

  const textColor = { color: hexToRgba('#fff', 0.7) };

  const getRole = (i: number) => {
    if (variant === 'secondary') return;
    return i % 2 === 0 ? 'dietician' : 'user';
  };

  const MarkdownText = (text: string) => {
    return (
      <Markdown
        css={{
          fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
          fontSize: 32,
          color: variant === 'primary' ? '#160B2C' : '#fff',
          marginBottom: 12,
          [presets.md]: { fontSize: 48 },
        }}
        source={text}
        allowDangerousHtml
        disallowedTypes={[]}
      />
    );
  };

  return (
    <div css={{ marginBottom: 64 }}>
      <div css={containerStyles}>
        {intl.locale === 'de' ? (
          <>
            <MonoFontLabel>{subTitle}</MonoFontLabel>
            {MarkdownText(title)}
          </>
        ) : (
          <h1
            css={{
              fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 32,
              color: variant === 'primary' ? '#160B2C' : '#fff',
              marginBottom: 12,
              [presets.md]: { fontSize: 48 },
            }}
          >
            {match
              ? title.replace(match[0], '').replace(/<[^>]*>/g, '')
              : title.replace(/<[^>]*>/g, '')}
            <span
              css={{ position: 'relative', display: 'block', fontWeight: 600 }}
            >
              {lastWord ? boldText.replace(lastWord, '') : boldText}
              {lastWord && (
                <span css={{ position: 'relative' }}>
                  {lastWord}
                  <EyeLashes
                    width={40}
                    height={26}
                    css={{
                      position: 'absolute',
                      right: -28,
                      bottom: 24,
                      [presets.md]: { bottom: 32 },
                    }}
                  />
                </span>
              )}
            </span>
          </h1>
        )}

        <Text
          css={variant === 'secondary' ? textColor : {}}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {intl.locale === 'de' ? (
          <ButtonLink to={typeformEligiblityCheckerLinkProduct}>
            {eligibleTitle}
          </ButtonLink>
        ) : (
          <AppDownloadButtons
            appStoreLink={appStoreLink}
            playStoreLink={playStoreLink}
          />
        )}
      </div>
      <div
        css={css`
          margin: 0 auto;
          width: 100%;
          max-width: 1440px;
          min-height: 600px;
          background-image: url(${intl.locale === 'de' ? heroDE : heroEN});
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;

          ${presets.md} {
            min-height: 820px;
          }
        `}
      >
        <div
          css={{
            display: 'flex',
            margin: '0 auto',
            padding: '0 24px',
            maxWidth: 560,
            minHeight: 600,
            flexDirection: 'column',
            justifyContent: 'center',
            [presets.md]: {
              minHeight: 820,
            },
          }}
        >
          {!hideChatBubbles &&
            chatBubbles.map((chatBubble, i) => (
              <ChatBubble
                key={chatBubble.id}
                {...chatBubble}
                pullRight={i % 2 !== 0}
                role={getRole(i)}
                {...{ variant }}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

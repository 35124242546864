import React from 'react';
import { useIntl } from 'react-intl';
import Hero from '../HeroView';
import Logos from './Logos';
import Science from './Science';
import Tracking from './Tracking';
import DownloadApp from './DownloadApp';
import Employers from './Employers';
import Mission from './Mission';
import PersonalizedPrograms from './PersonalizedPrograms';
import { ContentfulHomepage } from '../../graphql-types';
import heroDE from '../../assets/images/hero_de.png';
import heroEN from '../../assets/images/hero_en.png';
import VideoSection from './VideoSection';

interface HomepageProps {
  homepage: ContentfulHomepage;
}

export default function Homepage({ homepage }: HomepageProps) {
  const intl = useIntl();

  return (
    <div>
      <Hero
        title={homepage.heroTitle}
        text={homepage.heroText.childMarkdownRemark.rawMarkdownBody}
        chatBubbles={homepage.chatBubbles}
        hideChatBubbles={intl.locale === 'de'}
        variant="primary"
        {...{ heroDE, heroEN }}
        appStoreLink={homepage.appStoreDownloadLink}
        playStoreLink={homepage.googlePlayStoreDownloadLink}
        subTitle={'Kostenfrei auf Rezept'}
      />
      <Logos logos={homepage.logos} />
      <Science {...homepage.scienceSection} />
      <Tracking {...homepage.trackingSection} />
      {homepage.video && <VideoSection {...homepage.video} />}
      {intl.locale !== 'de' && <DownloadApp />}
      <Employers {...homepage.employersSection} logos={homepage.logos} />
      <PersonalizedPrograms {...homepage.personalizedPrograms} />
      <Mission {...homepage.missionSection} />
    </div>
  );
}
